
import Vue from 'vue';
import { mapState } from "vuex";


export default Vue.extend({
  name: 'App',

  data: () => ({
    //
  }),
  computed: {
    ...mapState(["notifications"]),
    ...mapState(["rol"]),
  },
});
