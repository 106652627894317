import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

import recinto from '@/assets/svg/recinto.vue'

export default new Vuetify({

    icons: {
        values: {
            recinto: {
                component: recinto
            },
          
        }
    },
    theme: {
        themes:{
            light: {
                background: '#272F42',
                backgroundBox : '#30384d',
                backgroundBoxHeaderTable : '#2B3345',
                secondaryColorFont:'#6E7E99',
            }
        }
    }
});
