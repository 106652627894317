
import axios from 'axios'
import store from '@/store'
import router from '../router'


export default () => {

    

    const instance = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL,
        headers: {
            // Authorization: `Bearer ${store.state.token}`
            auth: `${store.state.token}`

        }
    })

    instance.interceptors.response.use(
        (response) => {
            return response
        },
        function (error) {
            const originalRequest = error.config
            if (error.response.status === 401 && !originalRequest._retry) {

                
                store.dispatch('setNotification', {
                    title: "Error",
                    message:
                    error.message || "Error",
                    timeout: "6000",
                    color: "error",
                  })
                originalRequest._retry = true
                store.dispatch("clearToken").then(() => {
                    router.push("/login");
                })
            }
            // Do something with response error
            return Promise.reject(error)
        })


    return instance


}