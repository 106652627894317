
import HeaderVue from './Header.vue';

export default {
    name: 'dashboard',
    components: {
        HeaderVue
    }

}
