import Api from '@/services/Api'
const API_USER = "auth/";
// const API_URL = "api/";

export default {
    // eslint-disable-next-line
    register (credentials:any) {
        return Api().post('register', credentials)   
    },
    // eslint-disable-next-line
    login (credentials:any){
        return Api().post(API_USER + "login", credentials)
    },
    // eslint-disable-next-line
    logout (credentials:any) {
        return Api().post (API_USER + "logout" ,credentials)
    }
}