import Api from '@/services/Api'

export default {
    all(credentials:any) {
        return Api().get('camara-simulacion', credentials)
    },

    one(queryparam:any) {
        return Api().get('camara-simulacion/' + queryparam)
    },

    oneCamara(queryparam:any) {
        return Api().get('camara/ambito/' + queryparam)
    },

    add(credentials:any) {
        return Api().post('camara-simulacion', credentials)
    },

    edit(queryparam:any,credentials:any) {
        return Api().patch('camara-simulacion/' + queryparam, credentials)
    },

    delete (sim:any) {
        return Api().delete ('camara-simulacion/' + sim)
    }
}

