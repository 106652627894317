

import { mapActions, mapState } from 'vuex';
import Vue from 'vue';


import AuthenticationService from '../../services/AuthenticationService'
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { VMain, VRow, VCol, VCard, VCardTitle, VCardActions, VDivider, VCardText, VContainer, VTextField, VBtn } from 'vuetify/lib';

export default Vue.extend({
    name: "loginView",
    data() {
        return {
            showPassword: false,
            form: {
                user: "",
                password: "",
                ingresar: true,
            },
            error: {
                show: false,
                message: "",
            },

        };
    },

    // created() {

    // },

    mounted() {
        console.log(this.$refs.login);
    },


    methods: {


        ...mapActions(["showToast", "setNotification"]),

        async login() {

            try {
                this.form.ingresar = false;
                const { user, password } = this.form;
                const response = await AuthenticationService.login({
                    username: user,
                    password: password,
                });
                this.$store.dispatch("setToken", response.data.token);
                // this.$store.dispatch("setId", response.data.id);
                this.$store.dispatch("setRol", response.data.rol);
                this.$store.dispatch("setUsername", response.data.username || user);
                this.$router.push("/");
                // eslint-disable-next-line
            } catch (err: any) {
                this.form.ingresar = true;

                this.setNotification({
                    title: "Error",
                    message:
                        err.response.data.message || "Error al intentar iniciar sesión",
                    timeout: "6000",
                    color: "error",
                });
            }

        }
    },

})
