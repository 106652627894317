import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import store from '../store';

import Home from '../views/HomeView.vue'

import Simulacion from '../views/simulacion/Simulacion.vue'

import Dashboard from '../components/dashboard/dashboard.vue'


import Login from '../views/auth/LoginView.vue'

import Usuarios from '@/views/usuario/Usuarios.vue'


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Dashboard,
    meta: {
      requireAuth: true
    },
    children: [{
      path: '',
      name: 'Home',
      component: Home,
    },
    {
      path: 'simulacion/:idsimulacion',
      name: 'Simulacion',
      component: Simulacion
    },
    {
      path: 'usuarios',
      name: 'usuarios',
      component: Usuarios,
    },
    ]
  },
  {
    path: '/auth',
    component: () => import('@/views/auth/TemplateAuth.vue'),
    meta: {
      requireAuth: false
    },
    children: [{
      path: '/login',
      name: 'Login',
      component: Login
    }
    ]
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.NODE_ENV === 'production'
    ? '/sb/'
    : '/',
  routes
})


router.beforeEach((to, from, next) => {
  const requireAuth = to.matched.some(record => record.meta.requireAuth)

  /****Para cerrar el sidebar si esta en dispositivo pequeño */
  // if (store.state.sidebar.float) {
  //   store.state.sidebar.active = false;
  // }

  /*************Para controlar el acceso permitido ********* */
  if (requireAuth && !store.getters.isAuthenticated) {
    // console.log(from);

    next('/login')
  } else if (store.getters.isAuthenticated && to.name == 'login') {
    next('/')
  } else {

    next()
  }
  // next();
})

export default router
