<template>
    <div>


        <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="true"
            :paginate-elements-by-height="1100" filename="informe" :pdf-quality="2" :manual-pagination="false"
            pdf-format="a4" pdf-orientation="portrait" pdf-content-width="790px" @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()" @hasGenerated="hasGenerated($event)" ref="html2Pdf">


            <section slot="pdf-content">

                <section class="pdf-item">
                    <v-app>
                        <!-- <v-btn @click="generateReport()"></v-btn> -->
                        <v-main>


                            <v-container fluid>
                                <v-row>
                                    <v-col>
                                        <div class=" text-subtitle-1">
                                            Simulaci&oacute;n de bancas: {{ data.Nombre }}
                                        </div>
                                        <div class=" text-h6">
                                            Proyecci&oacute;n: {{ proyeccion }}
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>

                                        <v-card outlined>
                                            <v-card-title class="pb-1 text-subtitle-1 blue--text text--darken-1">
                                                Electores/as
                                            </v-card-title>
                                            <v-card-text class=" text-h5 font-weight-bold">
                                                {{ numberWithCommas(electores) }}
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col>
                                        <v-card outlined>
                                            <v-card-title class="pb-1 text-subtitle-1 blue--text text--darken-1">
                                                Participaci&oacute;n
                                            </v-card-title>
                                            <v-card-text class=" text-h5 font-weight-bold">
                                                {{ numberWithCommas(cantidadVotosTotales) }}
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col>
                                        <v-card outlined>
                                            <v-card-title class="pb-1 text-subtitle-1 blue--text text--darken-1">
                                                Votos en blanco
                                            </v-card-title>
                                            <v-card-text class=" text-h5 font-weight-bold">
                                                {{ numberWithCommas(blancos) }}
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col>
                                        <v-card outlined>
                                            <v-card-title class="pb-1 text-subtitle-1 blue--text text--darken-1">
                                                Votos nulos
                                            </v-card-title>
                                            <v-card-text class=" text-h5 font-weight-bold">
                                                {{ numberWithCommas(nulos) }}
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <v-card outlined>
                                            <v-card-title class="text-subtitle-1 blue--text ">
                                                {{ proyeccion }}
                                            </v-card-title>
                                            <v-card-text v-if="dataResultados.length">
                                                <v-row>
                                                    <v-col cols="3" class="text-uppercase text-caption">
                                                        <p>Composición actual</p>
                                                        <p>
                                                            <v-badge inline :color="dataResultados[0].color" left>
                                                                {{ dataResultados[0].name }}
                                                            </v-badge>
                                                        </p>
                                                        <p>
                                                            <v-badge inline :color="dataResultados[1].color" left>
                                                                {{ dataResultados[1].name }}
                                                            </v-badge>
                                                        </p>
                                                        <p>
                                                            <v-badge inline :color="dataResultados[2].color" left>
                                                                {{ dataResultados[2].name }}
                                                            </v-badge>
                                                        </p>
                                                        <p>
                                                            <v-badge inline :color="dataResultados[3].color" left>
                                                                {{ dataResultados[3].name }}
                                                            </v-badge>
                                                        </p>
                                                    </v-col>
                                                    <v-col cols="3" class="text-uppercase text-caption">
                                                        <p>Vence mandato</p>
                                                        <p>
                                                            <v-badge inline :color="dataResultados[0].color" left>
                                                                {{ dataResultados[0].nameshort }} ({{ venceMandato('FDT')
                                                                }})
                                                            </v-badge>
                                                        </p>
                                                        <p>
                                                            <v-badge inline :color="dataResultados[1].color" left>
                                                                {{ dataResultados[1].nameshort }} ({{ venceMandato('JXC')
                                                                }})
                                                            </v-badge>
                                                        </p>
                                                        <p>
                                                            <v-badge inline :color="dataResultados[2].color" left>
                                                                {{ dataResultados[2].nameshort }} ({{ venceMandato('Otras')
                                                                }})
                                                            </v-badge>
                                                        </p>
                                                        <p>
                                                            <v-badge inline :color="dataResultados[3].color" left>
                                                                {{ dataResultados[3].nameshort }} ({{
                                                                    venceMandato('Otras_2')
                                                                }})
                                                            </v-badge>
                                                        </p>
                                                    </v-col>
                                                    <v-col cols="3" class="text-uppercase text-caption">
                                                        <p>Proyección</p>
                                                        <p>
                                                            <v-badge inline :color="dataResultados[0].color" left>
                                                                {{ dataResultados[4].name }}
                                                            </v-badge>
                                                        </p>
                                                        <p>
                                                            <v-badge inline :color="dataResultados[1].color" left>
                                                                {{ dataResultados[5].name }}
                                                            </v-badge>
                                                        </p>
                                                        <p>
                                                            <v-badge inline :color="dataResultados[2].color" left>
                                                                {{ dataResultados[6].name }}
                                                            </v-badge>
                                                        </p>
                                                        <p>
                                                            <v-badge inline :color="dataResultados[3].color" left>
                                                                {{ dataResultados[7].name }}
                                                            </v-badge>
                                                        </p>
                                                    </v-col>
                                                    <v-col cols="2" class="text-uppercase text-caption text-center">
                                                        <p class="text-start text-no-wrap">Bancas Obtenidas</p>
                                                        <p
                                                            :class="((obtenidos('FDT') - venceMandato('FDT')) >= 0) ? (obtenidos('FDT') - venceMandato('FDT') == 0) ? 'py-1 lime--text' : ' py-1 light-green--text' : ' py-1 error--text'">
                                                            {{ obtenidos('FDT') }}</p>
                                                        <p
                                                            :class="((obtenidos('JXC') - venceMandato('JXC')) >= 0) ? (obtenidos('JXC') - venceMandato('JXC') == 0) ? 'py-1 lime--text' : ' py-1 light-green--text' : ' py-1 error--text'">
                                                            {{ obtenidos('JXC') }}</p>
                                                        <p
                                                            :class="((obtenidos('Otras') - venceMandato('Otras')) >= 0) ? (obtenidos('Otras') - venceMandato('Otras') == 0) ? 'py-1  lime--text' : ' py-1 light-green--text' : ' py-1 error--text'">
                                                            {{ obtenidos('Otras') }}</p>
                                                        <p
                                                            :class="((obtenidos('Otras_2') - venceMandato('Otras_2')) >= 0) ? (obtenidos('Otras_2') - venceMandato('Otras_2') == 0) ? 'py-1  lime--text' : ' py-1 light-green--text' : ' py-1 error--text'">
                                                            {{ obtenidos('Otras_2') }}</p>
                                                    </v-col>
                                                    <v-col cols="1" class="text-uppercase text-caption">
                                                        <p class="text-start">Dif.</p>
                                                        <p class=" text-no-wrap"
                                                            :class="((obtenidos('FDT') - venceMandato('FDT')) >= 0) ? (obtenidos('FDT') - venceMandato('FDT') == 0) ? 'py-1 lime--text' : ' pt-1 light-green--text' : ' pt-1 error--text'">
                                                            <span class=" grey--text text--darken-4">
                                                                {{ obtenidos('FDT') - venceMandato('FDT') }}
                                                            </span>
                                                            <v-icon v-if="(obtenidos('FDT') - venceMandato('FDT')) > 0"
                                                                color="light-green">mdi-arrow-up-thin

                                                            </v-icon>
                                                            <v-icon color="lime" size="small" class="pl-2"
                                                                v-else-if="((obtenidos('FDT') - venceMandato('FDT')) == 0)">
                                                                mdi-equal
                                                            </v-icon>
                                                            <v-icon v-else color="error">
                                                                mdi-arrow-down-thin
                                                            </v-icon>
                                                        </p>
                                                        <p class=" text-no-wrap"
                                                            :class="((obtenidos('JXC') - venceMandato('JXC')) >= 0) ? ' pt-1 light-green--text' : ' pt-1 error--text'">
                                                            <span class=" grey--text text--darken-4">
                                                                {{ obtenidos('JXC') - venceMandato('JXC') }}
                                                            </span>
                                                            <v-icon v-if="(obtenidos('JXC') - venceMandato('JXC')) > 0"
                                                                color="light-green">mdi-arrow-up-thin

                                                            </v-icon>
                                                            <v-icon color="lime" size="small" class="pl-2"
                                                                v-else-if="((obtenidos('JXC') - venceMandato('JXC')) == 0)">
                                                                mdi-equal
                                                            </v-icon>
                                                            <v-icon v-else color="error">
                                                                mdi-arrow-down-thin
                                                            </v-icon>
                                                        </p>
                                                        <p class=" text-no-wrap"
                                                            :class="((obtenidos('Otras') - venceMandato('Otras')) >= 0) ? ' pt-1 light-green--text' : ' pt-1 error--text'">
                                                            <span class=" grey--text text--darken-4">
                                                                {{ obtenidos('Otras') - venceMandato('Otras') }}
                                                            </span>
                                                            <v-icon v-if="(obtenidos('Otras') - venceMandato('Otras')) > 0"
                                                                color="light-green">mdi-arrow-up-thin

                                                            </v-icon>
                                                            <v-icon color="lime" size="small" class="pl-2"
                                                                v-else-if="((obtenidos('Otras') - venceMandato('Otras')) == 0)">
                                                                mdi-equal
                                                            </v-icon>
                                                            <v-icon v-else color="error">
                                                                mdi-arrow-down-thin
                                                            </v-icon>
                                                        </p>
                                                        <p class=" text-no-wrap"
                                                            :class="((obtenidos('Otras_2') - venceMandato('Otras_2')) >= 0) ? ' pt-1 light-green--text' : ' pt-1 error--text'">
                                                            <span class=" grey--text text--darken-4">
                                                                {{ obtenidos('Otras_2') - venceMandato('Otras_2') }}
                                                            </span>
                                                            <v-icon
                                                                v-if="(obtenidos('Otras_2') - venceMandato('Otras_2')) > 0"
                                                                color="light-green">mdi-arrow-up-thin

                                                            </v-icon>
                                                            <v-icon color="lime" size="small" class="pl-2"
                                                                v-else-if="((obtenidos('Otras_2') - venceMandato('Otras_2')) == 0)">
                                                                mdi-equal
                                                            </v-icon>
                                                            <v-icon v-else color="error">
                                                                mdi-arrow-down-thin
                                                            </v-icon>
                                                        </p>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="6">
                                        <v-card outlined>
                                            <camara title="Composición actual" ref="camaraActual" proyeccion="actual"
                                                :informe="true" :tipoCamara="data.Id" :simulacion="data"></camara>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="6">

                                        <v-card outlined>
                                            <camara title="Proyección" ref="camaraProyeccion" proyeccion="simulacion"
                                                :informe="true" :tipoCamara="data.Id" :simulacion="data"></camara>
                                        </v-card>

                                    </v-col>
                                </v-row>
                            </v-container>

                        </v-main>
                    </v-app>
                </section>


                <section class="pdf-item">
                    <v-app>

                        <v-main>

                            <v-container fluid>
                                <v-row>
                                    <v-col class="text-caption">

                                        <v-simple-table dense class="informetable">
                                            <thead>
                                                <tr>
                                                    <th class="text-left  px-1 font-weight-regular">
                                                        Distrito
                                                    </th>
                                                    <th class="text-left  px-1 font-weight-regular">
                                                        Electores
                                                    </th>
                                                    <th class="text-left  px-1 font-weight-regular">
                                                        Participaci&oacute;n
                                                    </th>
                                                    <th class="text-center" colspan="6">
                                                        <div class=" font-weight-regular grey lighten-3 ">VOTOS</div>
                                                        <div class=" d-flex  justify-space-between "
                                                            style="font-size: 9px; ">
                                                            <div class="text-center  ">
                                                                Blancos
                                                            </div>
                                                            <div class="text-center  ">
                                                                Nulos
                                                            </div>
                                                            <div class="text-center  ">
                                                                FDT
                                                            </div>
                                                            <div class="text-center  ">
                                                                JXC
                                                            </div>
                                                            <div class="text-center ">
                                                                Otros
                                                            </div>
                                                            <div class="text-center pr-1  text-no-wrap">
                                                                Otros 2</div>
                                                        </div>
                                                    </th>
                                                    <th class="text-center" colspan="4">
                                                        <div class="font-weight-regular grey lighten-3">BANCAS</div>
                                                        <div class="d-flex d-row justify-space-between "
                                                            style="font-size: 9px;">
                                                            <div class="text-center pl-1 ">
                                                                FDT
                                                            </div>
                                                            <div class="text-center pl-1 ">
                                                                JXC
                                                            </div>
                                                            <div class="text-center pl-1 ">
                                                                Otros
                                                            </div>
                                                            <div class="text-center pl-1 ">
                                                                Otros 2
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="twolines" v-for="(item, index) in distribuciones"
                                                    :class="{ 'grey lighten-3 ': (index % 2 == 0) }" :key="item.Electores">
                                                    <td class="px-1 pb-1 font-weight-bold  text-capitalize  "
                                                        style="max-width: 120px;">{{ item.Distrito.toLowerCase() }}</td>
                                                    <td class="px-1 text-no-wrap">{{ item.Electores }}</td>
                                                    <td class="px-1 text-no-wrap">{{ item.Participacion }} %</td>
                                                    <td class="px-1 text-no-wrap">{{ item.Blancos }} %</td>
                                                    <td class="px-1 text-no-wrap">{{ item.Nulos }} %</td>
                                                    <td class="px-1 text-no-wrap">{{ item.Fuerzas[0].Porcentaje }} %</td>
                                                    <td class="px-1 text-no-wrap">{{ item.Fuerzas[1].Porcentaje }} %</td>
                                                    <td class="px-1 text-no-wrap">{{ item.Fuerzas[2].Porcentaje }} %</td>
                                                    <td class="px-1 text-no-wrap">{{ item.Fuerzas[3].Porcentaje }} %</td>
                                                    <td class="px-1 text-no-wrap font-weight-bold">
                                                        <div class="text-center pb-1">

                                                            {{ obtenidosDistrito(item.Fuerzas[0]) }}
                                                            <span>(</span>
                                                            <span v-if="(ganadosDistrito(item.Fuerzas[0])) > 0"
                                                                class=" light-green--text">
                                                                {{ ganadosDistrito(item.Fuerzas[0]) }}↑
                                                            </span>
                                                            <span v-else-if="(ganadosDistrito(item.Fuerzas[0])) == 0"
                                                                class=" lime--text">
                                                                {{ ganadosDistrito(item.Fuerzas[0]) }}=
                                                            </span>
                                                            <span v-else class=" error--text">
                                                                {{ ganadosDistrito(item.Fuerzas[0]) }}↓
                                                            </span>
                                                            <span>)</span>
                                                        </div>
                                                        <div class=" grey--text text--lighten-3 text-center text-uppercase font-weight-regular"
                                                            :class="((ganadosDistrito(item.Fuerzas[0])) >= 0) ? (ganadosDistrito(item.Fuerzas[0]) == 0) ? 'lime' : 'light-green' : 'error'">
                                                            total:{{ totalDistrito(item.Fuerzas[0]) }}
                                                        </div>

                                                    </td>
                                                    <td class="px-1 text-no-wrap font-weight-bold">
                                                        <div class="text-center pb-1">

                                                            {{ obtenidosDistrito(item.Fuerzas[1]) }}
                                                            <span>(</span>
                                                            <span v-if="(ganadosDistrito(item.Fuerzas[1])) > 0"
                                                                class=" light-green--text">
                                                                {{ ganadosDistrito(item.Fuerzas[1]) }}↑
                                                            </span>
                                                            <span v-else-if="(ganadosDistrito(item.Fuerzas[1])) == 0"
                                                                class=" lime--text">
                                                                {{ ganadosDistrito(item.Fuerzas[1]) }}=
                                                            </span>
                                                            <span v-else class=" error--text">
                                                                {{ ganadosDistrito(item.Fuerzas[1]) }}↓
                                                            </span>
                                                            <span>)</span>
                                                        </div>
                                                        <div class=" grey--text text--lighten-3 text-center text-uppercase font-weight-regular"
                                                            :class="((ganadosDistrito(item.Fuerzas[1])) >= 0) ? (ganadosDistrito(item.Fuerzas[1]) == 0) ? 'lime' : 'light-green' : 'error'">
                                                            total:{{ totalDistrito(item.Fuerzas[1]) }}
                                                        </div>
                                                    </td>
                                                    <td class="px-1 text-no-wrap font-weight-bold">
                                                        <div class="text-center pb-1">

                                                            {{ obtenidosDistrito(item.Fuerzas[2]) }}
                                                            <span>(</span>
                                                            <span v-if="(ganadosDistrito(item.Fuerzas[2])) > 0"
                                                                class=" light-green--text">
                                                                {{ ganadosDistrito(item.Fuerzas[2]) }} ↑
                                                            </span>
                                                            <span v-else-if="(ganadosDistrito(item.Fuerzas[2])) == 0"
                                                                class=" lime--text">
                                                                {{ ganadosDistrito(item.Fuerzas[2]) }}=
                                                            </span>
                                                            <span v-else class=" error--text">
                                                                {{ ganadosDistrito(item.Fuerzas[2]) }}↓
                                                            </span>
                                                            <span>)</span>
                                                        </div>
                                                        <div
                                                            class=" grey--text text--lighten-3 text-center text-uppercase font-weight-regular lime">
                                                            total:{{ totalDistrito(item.Fuerzas[2]) }}
                                                        </div>
                                                    </td>
                                                    <td class="px-1 text-no-wrap font-weight-bold">
                                                        <div class="text-center pb-1">

                                                            {{ obtenidosDistrito(item.Fuerzas[3]) }}
                                                            <span>(</span>
                                                            <span v-if="(ganadosDistrito(item.Fuerzas[3])) > 0"
                                                                class=" light-green--text">
                                                                {{ ganadosDistrito(item.Fuerzas[3]) }}↑
                                                            </span>
                                                            <span v-else-if="(ganadosDistrito(item.Fuerzas[3])) == 0"
                                                                class=" lime--text">
                                                                {{ ganadosDistrito(item.Fuerzas[3]) }}=
                                                            </span>
                                                            <span v-else class=" error--text">
                                                                {{ ganadosDistrito(item.Fuerzas[3]) }}↓
                                                            </span>
                                                            <span>)</span>
                                                        </div>
                                                        <div
                                                            class="lime grey--text text--lighten-3 text-center text-uppercase font-weight-regular">
                                                            total:{{ totalDistrito(item.Fuerzas[3]) }}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>


                                    </v-col>
                                </v-row>
                            </v-container>

                        </v-main>
                    </v-app>

                </section>

                <!-- PDF Content Here -->





            </section>




        </vue-html2pdf>


    </div>
</template>

<script>

import VueHtml2pdf from 'vue-html2pdf'
import Camara from '../simulacion/camara.vue'
import { mapActions, mapState } from 'vuex'

export default {
    props: {
        data: {
            type: Object
        },
        camaras: {
            type: Array
        }
    },
    components: {
        VueHtml2pdf,
        Camara
    },

    data: () => ({

    }),

    methods: {


        /*
            Generate Report using refs and calling the
            refs function generatePdf()
        */
        generateReport() {

            this.$refs.camaraActual.dale()
            this.$refs.camaraProyeccion.dale()
            this.$refs.html2Pdf.generatePdf()


        },

        onProgress($event) {

            if ($event == 100) {
                this.$emit('done', true)
            }
        },

        hasStartedGeneration() {
            console.log("hasStartedGeneration");
        },

        hasGenerated($event) {
            console.log("hasGenerated", $event);
        },

        venceMandato(agrupacion) {

            let res = this.distribuciones.map(agrupaciondistribucion => agrupaciondistribucion.Fuerzas.find(fuerza => fuerza.Fuerza == agrupacion))
            return res.reduce((cantidadAcumulador, cantidad) => cantidadAcumulador + cantidad.Renueva, 0)
        },

        obtenidos(agrupacion) {

            let res = this.distribuciones.map(agrupaciondistribucion => agrupaciondistribucion.Fuerzas.find(fuerza => fuerza.Fuerza == agrupacion))
            return res.reduce((cantidadAcumulador, cantidad) => cantidadAcumulador + cantidad.Ganadas, 0)
        },

        venceMandatoDistrito(fuerza) {

            return fuerza.Renueva

            // let res = this.distribuciones.map(agrupaciondistribucion => agrupaciondistribucion.Fuerzas.find(fuerza => fuerza.Fuerza == agrupacion))
            // return res.reduce((cantidadAcumulador, cantidad) => cantidadAcumulador + cantidad.Renueva, 0)
        },

        obtenidosDistrito(fuerza) {

            return fuerza.Ganadas
            // let res = this.distribuciones.map(agrupaciondistribucion => agrupaciondistribucion.Fuerzas.find(fuerza => fuerza.Fuerza == agrupacion))
            // return res.reduce((cantidadAcumulador, cantidad) => cantidadAcumulador + cantidad.Ganadas, 0)
        },

        ganadosDistrito(fuerza) {
            // console.log(fuerza);
            return this.obtenidosDistrito(fuerza) - this.venceMandatoDistrito(fuerza)
        },

        totalDistrito(fuerza) {
            // console.log(fuerza);
            return fuerza.Bancas - fuerza.Renueva + fuerza.Ganadas
        },

        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }




    },

    computed: {

        proyeccion() {
            let camara = this.camaras.find(c => this.data.Id == c.value)
            return (camara) ? camara.text : ''
        },

        distribuciones() {
            return this.data.Distribucion.filter((d) => d.Fuerzas.reduce((acc, f) => acc + f.Renueva, 0) > 0)

        },

        cantidadVotosTotales() {
            return (!this.distribuciones.length) ? 0 : this.distribuciones.reduce((acc, cant) => acc + Number(((cant.Electores * cant.Participacion) / 100).toFixed()), 0)
        },

        cantidad() {
            return (!this.distribuciones.length) ? 0 : this.distribuciones.reduce((acc, cant) => acc + Number(((cant.Electores * cant.Participacion) / 100).toFixed()), 0)
        },

        electores() {
            return this.distribuciones.reduce((cantidadAcumulador, cantidad) => cantidadAcumulador + cantidad.Electores, 0)
        },

        participacion() {
            return this.data.Distribucion.reduce((cantidadAcumulador, cantidad) => cantidadAcumulador + cantidad.Participacion, 0)
        },

        blancos() {
            // console.log(this.distribuciones[0]);
            return this.distribuciones.reduce((acc, d) => acc + Number((Number(((d.Electores * d.Participacion) / 100).toFixed() * d.Blancos) / 100).toFixed()), 0)
        },

        nulos() {
            return this.distribuciones.reduce((acc, d) => acc + Number((Number(((d.Electores * d.Participacion) / 100).toFixed() * d.Nulos) / 100).toFixed()), 0)


        },

        ...mapState([
            'dataResultados',
        ])


    }


    // mounted() {
    //     console.log('mounted');
    //     this.generateReport()
    // }


}
</script>


<style lang="scss">
.twolines {
    height: 2rem;
    line-height: 1em;
}

.informetable {
    font-size: 0.7rem;

    td,
    th,
    .subheaderinforme {
        border: 1px solid #f1efef;
        padding: 0px !important;
        font-size: 0.7rem !important;
    }

    ;

}
</style>