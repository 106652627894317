

import Vue from 'vue'
import { mapActions, mapState } from 'vuex'


export default Vue.extend({

  props: {
    title: String,
    legisladores: Object,
    tipoCamara: {
      type: Number,
      required: true,
    },
    simulacion: {
      type: Object,
      required: true,
    },
    proyeccion: {
      type: String,
      default: 'simulacion'
    },
    informe: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    series: [
      {
        name: "Frente De Todos",
        data: [],
      },
      {
        name: "Juntos por el cambio",
        data: [],
      },
      {
        name: "Otros",
        data: [],
      },
    ],

    seriesOver: [],

    chartOptions: {
      legend: {
        show: false,

      },
      markers: {
        size: 5,
        // strokeColor:'#30384d'
        strokeWidth: 0
      },
      chart: {
        type: "scatter",
        foreColor: '#fff',
        sparkline: {
          enabled: true,
        },
        animations: {
          enabled: true,
        }
      },


      tooltip: {
        enabled: false,
      },

      colors: ["#03a9f4", "#ffea00", "#9E9E9E", "#d8d8d8"],

      title: {
        text: '',
        align: "center",
        style: {
          fontSize: '28px',
          fontWeight: 'bold',
          fontFamily: undefined,
          color: '#263238'
        }
      },
    },


    puntosSenadores: [
      [1, 2],
      [1, 3],
      [1, 4],
      [1, 5],

      [2, 1],
      [2, 2],
      [2, 3],
      [2, 4],
      [2, 5],
      [2, 6],

      [3, 1],
      [3, 2],
      [3, 3],
      [3, 4],
      [3, 5],
      [3, 6],
      [3, 7],

      [4, 1],
      [4, 2],
      [4, 3],
      [4, 4],
      [4, 5],
      [4, 6],
      [4, 7],
      [4, 8],

      [5, 3],
      [5, 4],
      [5, 5],
      [5, 6],
      [5, 7],
      [5, 8],

      [6, 4],
      [6, 5],
      [6, 6],
      [6, 7],
      [6, 8],

      [7, 4],
      [7, 5],
      [7, 6],
      [7, 7],
      [7, 8],

      [8, 3],
      [8, 4],
      [8, 5],
      [8, 6],
      [8, 7],
      [8, 8],

      [9, 1],
      [9, 2],
      [9, 3],
      [9, 4],
      [9, 5],
      [9, 6],
      [9, 7],
      [9, 8],

      [10, 1],
      [10, 2],
      [10, 3],
      [10, 4],
      [10, 5],
      [10, 6],
      [10, 7],

      [11, 1],
      [11, 2],
      [11, 3],
      [11, 4],
      [11, 5],
      [11, 6],

      [12, 2],
      [12, 3],
      [12, 4],
      [12, 5],
    ],

    puntosSenadoresPBA: [
      [1, 1],
      [1, 2],
      [1, 3],
      [1, 4],

      [2, 1],
      [2, 2],
      [2, 3],
      [2, 4],
      [2, 5],
      [2, 6],

      [3, 2],
      [3, 3],
      [3, 4],
      [3, 5],
      [3, 6],
      [3, 7],

      [4, 3],
      [4, 4],
      [4, 5],
      [4, 6],
      [4, 7],

      [5, 4],
      [5, 5],
      [5, 6],
      [5, 7],

      [6, 3],
      [6, 4],
      [6, 5],
      [6, 6],
      [6, 7],

      [7, 2],
      [7, 3],
      [7, 4],
      [7, 5],
      [7, 6],
      [7, 7],

      [8, 1],
      [8, 2],
      [8, 3],
      [8, 4],
      [8, 5],
      [8, 6],

      [9, 1],
      [9, 2],
      [9, 3],
      [9, 4],
    ],

    puntosDiputados: [
      [1, 3],
      [1, 4],
      [1, 5],
      [1, 6],
      [1, 7],

      [2, 2],
      [2, 3],
      [2, 4],
      [2, 5],
      [2, 6],
      [2, 7],
      [2, 8],
      [2, 9],

      [3, 1],
      [3, 2],
      [3, 3],
      [3, 4],
      [3, 5],
      [3, 6],
      [3, 7],
      [3, 8],
      [3, 9],
      [3, 10],
      [3, 11],

      [4, 1],
      [4, 2],
      [4, 3],
      [4, 4],
      [4, 5],
      [4, 6],
      [4, 7],
      [4, 8],
      [4, 9],
      [4, 10],
      [4, 11],
      [4, 12],

      [5, 1],
      [5, 2],
      [5, 3],
      [5, 4],
      [5, 5],
      [5, 6],
      [5, 7],
      [5, 8],
      [5, 9],
      [5, 10],
      [5, 11],
      [5, 12],
      [5, 13],

      [6, 1],
      [6, 2],
      [6, 3],
      [6, 4],
      [6, 5],
      [6, 6],
      [6, 7],
      [6, 8],
      [6, 9],
      [6, 10],
      [6, 11],
      [6, 12],
      [6, 13],
      [6, 14],

      [7, 1],
      [7, 2],
      [7, 3],
      [7, 4],
      [7, 5],
      [7, 6],
      [7, 7],
      [7, 8],
      [7, 9],
      [7, 10],
      [7, 11],
      [7, 12],
      [7, 13],
      [7, 14],

      [8, 1],
      [8, 2],
      [8, 3],
      [8, 4],
      [8, 5],
      [8, 6],
      [8, 7],
      [8, 8],
      [8, 9],
      [8, 10],
      [8, 11],
      [8, 12],
      [8, 13],
      [8, 14],
      [8, 15],

      [9, 5],
      [9, 6],
      [9, 7],
      [9, 8],
      [9, 9],
      [9, 10],
      [9, 11],
      [9, 12],
      [9, 13],
      [9, 14],
      [9, 15],

      [10, 7],
      [10, 8],
      [10, 9],
      [10, 10],
      [10, 11],
      [10, 12],
      [10, 13],
      [10, 14],
      [10, 15],

      [11, 8],
      [11, 9],
      [11, 10],
      [11, 11],
      [11, 12],
      [11, 13],
      [11, 14],
      [11, 15],

      [12, 8],
      [12, 9],
      [12, 10],
      [12, 11],
      [12, 12],
      [12, 13],
      [12, 14],
      [12, 15],

      [13, 7],
      [13, 8],
      [13, 9],
      [13, 10],
      [13, 11],
      [13, 12],
      [13, 13],
      [13, 14],
      [13, 15],

      [14, 8],
      [14, 9],
      [14, 10],
      [14, 11],
      [14, 12],
      [14, 13],
      [14, 14],
      [14, 15],

      [15, 7],
      [15, 8],
      [15, 9],
      [15, 10],
      [15, 11],
      [15, 12],
      [15, 13],
      [15, 14],
      [15, 15],

      [16, 5],
      [16, 6],
      [16, 7],
      [16, 8],
      [16, 9],
      [16, 10],
      [16, 11],
      [16, 12],
      [16, 13],
      [16, 14],
      [16, 15],

      [17, 1],
      [17, 2],
      [17, 3],
      [17, 4],
      [17, 5],
      [17, 6],
      [17, 7],
      [17, 8],
      [17, 9],
      [17, 10],
      [17, 11],
      [17, 12],
      [17, 13],
      [17, 14],
      [17, 15],

      [18, 1],
      [18, 2],
      [18, 3],
      [18, 4],
      [18, 5],
      [18, 6],
      [18, 7],
      [18, 8],
      [18, 9],
      [18, 10],
      [18, 11],
      [18, 12],
      [18, 13],
      [18, 14],

      [19, 1],
      [19, 2],
      [19, 3],
      [19, 4],
      [19, 5],
      [19, 6],
      [19, 7],
      [19, 8],
      [19, 9],
      [19, 10],
      [19, 11],
      [19, 12],
      [19, 13],
      [19, 14],

      [20, 1],
      [20, 2],
      [20, 3],
      [20, 4],
      [20, 5],
      [20, 6],
      [20, 7],
      [20, 8],
      [20, 9],
      [20, 10],
      [20, 11],
      [20, 12],
      [20, 13],

      [21, 1],
      [21, 2],
      [21, 3],
      [21, 4],
      [21, 5],
      [21, 6],
      [21, 7],
      [21, 8],
      [21, 9],
      [21, 10],
      [21, 11],
      [21, 12],

      [22, 1],
      [22, 2],
      [22, 3],
      [22, 4],
      [22, 5],
      [22, 6],
      [22, 7],
      [22, 8],
      [22, 9],
      [22, 10],
      [22, 11],

      [23, 2],
      [23, 3],
      [23, 4],
      [23, 5],
      [23, 6],
      [23, 7],
      [23, 8],
      [23, 9],

      [24, 3],
      [24, 4],
      [24, 5],
      [24, 6],
      [24, 7],
    ],

    puntosDiputadosPBA: [
      [1, 2],
      [1, 3],
      [1, 4],
      [1, 5],
      [1, 6],

      [2, 1],
      [2, 2],
      [2, 3],
      [2, 4],
      [2, 5],
      [2, 6],
      [2, 7],
      [2, 8],

      [3, 1],
      [3, 2],
      [3, 3],
      [3, 4],
      [3, 5],
      [3, 6],
      [3, 7],
      [3, 8],

      [4, 1],
      [4, 2],
      [4, 3],
      [4, 4],
      [4, 5],
      [4, 6],
      [4, 7],
      [4, 8],
      [4, 9],

      [5, 4],
      [5, 5],
      [5, 6],
      [5, 7],
      [5, 8],
      [5, 9],

      [6, 5],
      [6, 6],
      [6, 7],
      [6, 8],
      [6, 9],

      [7, 5],
      [7, 6],
      [7, 7],
      [7, 8],
      [7, 9],

      [8, 5],
      [8, 6],
      [8, 7],
      [8, 8],
      [8, 9],

      [9, 5],
      [9, 6],
      [9, 7],
      [9, 8],
      [9, 9],

      [10, 4],
      [10, 5],
      [10, 6],
      [10, 7],
      [10, 8],
      [10, 9],

      [11, 1],
      [11, 2],
      [11, 3],
      [11, 4],
      [11, 5],
      [11, 6],
      [11, 7],
      [11, 8],
      [11, 9],

      [12, 1],
      [12, 2],
      [12, 3],
      [12, 4],
      [12, 5],
      [12, 6],
      [12, 7],
      [12, 8],

      [13, 1],
      [13, 2],
      [13, 3],
      [13, 4],
      [13, 5],
      [13, 6],
      [13, 7],
      [13, 8],

      [14, 2],
      [14, 3],
      [14, 4],
      [14, 5],
      [14, 6],
    ],

    puntosCamara: [],

    render: true
  }),


  created() {

    switch (this.tipoCamara) {
      case 1:
        this.setDiputadosNacionales();
        break;
      case 2:
        this.setSenadoresNacionales();
        break;
      case 3:
        this.setDiputadosProvBa();
        break;
      case 4:
        this.setSenadoresProvBa();
        break;
    }
    if (this.informe) {
      // console.log(this.chartOptions.title.style.color);
      this.chartOptions.legend.labels = {
        colors: "rgba(0, 0, 0, 0.6)",
        useSeriesColors: false
      }
    }
  },

  mounted() {
    window.dispatchEvent(new Event('resize'))
  },

  watch: {
    simulacion () {
        switch (this.tipoCamara) {
          case 1:
            this.setDiputadosNacionales();
            break;
          case 2:
            this.setSenadoresNacionales();
            break;
          case 3:
            this.setDiputadosProvBa();
            break;
          case 4:
            this.setSenadoresProvBa();
            break;
        }
        // window.dispatchEvent(new Event('resize'))
      },
      // deep: true


    // }
  },

  methods: {

    generarGrafico() {

      (this.informe) ? this.chartOptions.legend.show = false : this.chartOptions.legend.show = true;


      let puntosCamaraAux = [...this.puntosCamara];

      let fdt = 0;
      let jxc = 0;
      let otros = 0;
      let otros2 = 0;

      if (this.proyeccion == 'simulacion') {
        this.simulacion.Distribucion.map((d: any) => {
          fdt += d.Fuerzas[0].Bancas - d.Fuerzas[0].Renueva + d.Fuerzas[0].Ganadas
          jxc += d.Fuerzas[1].Bancas - d.Fuerzas[1].Renueva + d.Fuerzas[1].Ganadas
          otros += d.Fuerzas[2].Bancas - d.Fuerzas[2].Renueva + d.Fuerzas[2].Ganadas
          otros2 += d.Fuerzas[3].Bancas - d.Fuerzas[3].Renueva + d.Fuerzas[3].Ganadas
        });
      } else {
        this.simulacion.Distribucion.map((d: any) => {
          fdt += d.Fuerzas[0].Bancas
          jxc += d.Fuerzas[1].Bancas
          otros += d.Fuerzas[2].Bancas
          otros2 += d.Fuerzas[3].Bancas
        });
      }


      this.series = [
        {
          name: '(' + fdt + ')' + " Frente De Todos",
          data: [],
        },
        {
          name: '(' + jxc + ')' + " Juntos por el cambio",
          data: [],
        },
        {
          name: '(' + otros + ')' + " Otros",
          data: [],
        },
        {
          name: '(' + otros2 + ')' + " Otros 2",
          data: [],
        }
      ]

      for (let index = 0; index < otros2; index++) {
        let cordenada = puntosCamaraAux.pop();
        this.series[3].data.push(cordenada);
      }

      for (let index = 0; index < otros; index++) {
        let cordenada = puntosCamaraAux.pop();
        this.series[2].data.push(cordenada);
      }

      for (let index = 0; index < jxc; index++) {
        let cordenada = puntosCamaraAux.pop();
        this.series[1].data.push(cordenada);
      }

      for (let index = 0; index < fdt; index++) {
        let cordenada = puntosCamaraAux.pop();
        this.series[0].data.push(cordenada);
      }

      this.seriesOver = [
        {
          name: " Frente De Todos   " + '(' + fdt + ')',
          nameshort: " Frente De Todos  ",
          color: "#03a9f4"
        },
        {
          name: " Juntos por el cambio   " + '(' + jxc + ')',
          nameshort: " Juntos por el cambio  ",
          color: "#ffea00"
        },
        {
          name: " Otros   " + '(' + otros + ')',
          nameshort: " Otros  ",
          color: "#9E9E9E"
        },
        {
          name: " Otros 2   " + '(' + otros2 + ')',
          nameshort: " Otros 2  ",
          color: "#d8d8d8"
        }
      ]
    },

    dale() {

      this.setData(this.seriesOver)
      // this.$refs.realtimeChart.render();
      // switch (this.tipoCamara) {
      //   case 1:
      //     this.setDiputadosNacionales();
      //     break;
      //   case 2:
      //     this.setSenadoresNacionales();
      //     break;
      //   case 3:
      //     this.setDiputadosProvBa();
      //     break;
      //   case 4:
      //     this.setSenadoresProvBa();
      //     break;
      // }
    },


    setSenadoresNacionales() {
      // this.chartOptions.title = {
      //   text: "Senadores Nacionales proyección",
      //   align: "center",
      // };

      // this.camara = "Cámara de senadores";

      this.puntosCamara = [...this.puntosSenadores];
      this.chartOptions.xaxis = { min: 0, max: 13 };
      this.chartOptions.yaxis = { min: -2, max: 9 };
      this.generarGrafico();
    },

    setDiputadosNacionales() {
      // this.camara = "Cámara de diputados";

      // this.chartOptions.title = {
      //   text: "Diputados Nacionales proyección",
      //   align: "center",
      //   style: {
      //     fontSize: '28px',
      //     fontWeight: 'bold',
      //     fontFamily: undefined,
      //     color: '#263238'
      //   }
      // };

      this.puntosCamara = [...this.puntosDiputados];
      this.chartOptions.xaxis = { min: 0, max: 25 };
      this.chartOptions.yaxis = { min: -3, max: 17 };
      this.chartOptions.markers.size = 5;
      this.generarGrafico();
    },

    setSenadoresProvBa() {
      // this.camara = "Cámara de senadores";

      // this.chartOptions.title = {
      //   text: "Senadores Provinciales PBA proyección",
      //   align: "center",
      // };

      this.puntosCamara = [...this.puntosSenadoresPBA];
      this.chartOptions.xaxis = { min: 0, max: 10 };
      this.chartOptions.yaxis = { min: -2, max: 8 };
      this.generarGrafico();
    },

    setDiputadosProvBa() {
      // this.camara = "Cámara de diputados";

      // this.chartOptions.title = {
      //   text: "Diputados Provinciales PBA proyección",
      //   align: "center",
      // };

      this.puntosCamara = [...this.puntosDiputadosPBA];
      this.chartOptions.xaxis = { min: 0, max: 15 };
      this.chartOptions.yaxis = { min: -2, max: 10 };
      this.chartOptions.markers.size = 6;
      this.generarGrafico();
    },

    ...mapActions([
      'setData'
    ]),

  },

  computed: {

    miembros() {
      return this.series.reduce((acc: any, current: any) => acc + current.data.length, 0)
    },

    currentCamara() {
      switch (this.tipoCamara) {
        case 1:
          return 'Diputados/as Nacionales'
        case 2:
          return 'Senadores/as Nacionales'
        case 3:
          return 'Diputados/as PBA'
        case 4:
          return 'Senadores/as PBA'
        default:
          return ''
      }
    },



    // ...mapState([
    //   'data',
    // ])

  }
})

