
import Vue from 'vue'
import { mapState } from "vuex";

export default Vue.extend({
    name: 'Header',
    computed: {
        ...mapState(["user", "rol"]),
    },
    methods: {
        logout() {
            this.$store.dispatch("LOGOUT_REQUEST").then(() => {
                this.$router.push("/login");
            });
        },
    },
})
